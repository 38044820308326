<template>
<div class="container main-body">

    <div class="row">
                <div class="col-md-3 col-sm-6 col-6">
                    <router-link to="/projects">
                    <article class="card card-post">
                        <img src="/assets/img/portfolio/thumbnails/1.jpg" class="card-img-top" />
                        <div class="card-body">
                            <h6 class="title">Power controller and Automation</h6>
                            <p class="small text-uppercase text-muted">Electrical</p>
                        </div>
                    </article>
                    </router-link>
                    <!-- card.// -->
                </div>
                <!-- col.// -->
                <div class="col-md-3 col-sm-6 col-6">
                    <router-link to="/projects">
                    <article class="card card-post">
                        <img src="/assets/img/portfolio/thumbnails/2.jpg" class="card-img-top" />
                        <div class="card-body">
                            <h6 class="title">Inveronmental Controller</h6>
                            <p class="small text-uppercase text-muted">Sensors & Transducer</p>
                        </div>
                    </article>
                    </router-link>
                    <!-- card.// -->
                </div>
                <!-- col.// -->
                <div class="col-md-3 col-sm-6 col-6">
                    <router-link to="/projects">
                    <article class="card card-post">
                        <img src="/assets/img/portfolio/thumbnails/3.jpg" class="card-img-top" />
                        <div class="card-body">
                            <h6 class="title">Raspis berry pi intergrated</h6>
                            <p class="small text-uppercase text-muted">Raspery Pi</p>
                        </div>
                    </article>
                    </router-link>
                    <!-- card.// -->
                </div>
                <!-- col.// -->
                <div class="col-md-3 col-sm-6 col-6">
                    <router-link to="/projects">
                    <article class="card card-post">
                        <img src="/assets/img/portfolio/thumbnails/4.jpg" class="card-img-top" />
                        <div class="card-body">
                            <h6 class="title">Circuit with Alduino</h6>
                            <p class="small text-uppercase text-muted">Arduino</p>
                        </div>
                    </article>
                    </router-link>
                    <!-- card.// -->
                </div>
                <!-- col.// -->
            </div>
</div>
</template>

<script>
import Category from "../components/Category.vue";

export default{
    
    name:"Categories",
    data(){
        return{

        }
    },
    components:{
        Category
    },
    computed:{
        categories(){
            return this.$store.state.categories;
        }
    }
    
}
</script>

<style scoped>
h6{
    text-decoration: none !important;
}
h6:hover{
    text-decoration: none !important;
}
</style>