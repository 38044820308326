<template>
    
        <div class="cardcontainer">
            <div class="photo">
                <img :src="'/assets/img/portfolio/thumbnails/'+project.image">
                <div class="photos">Tsh{{ project.price }}</div>
            </div>
            <div class="content">
                <p class="txt4">{{project.name}}</p>
            </div>
            <div class="footer">
                <p><router-link to="/project-info/" class="waves-effect waves-light btn btn-success" href="#">Read More</router-link><a id="heart"><span class="like"><i class="fa fa-heart"></i>Like</span></a></p>
                <p class="txt3"><i class="fa fa-eye"></i>105 Views <span class="comments"><i class="fa fa-comments"></i>45 Comments</span></p>
            </div>
        </div>
    
</template>

<script>
   export default{
      props:{
          project:Object
      }
   }
</script>

<style scoped>
body{
        margin: 0;
        padding: 0;
        font-family: 'roboto' , sans-serif;
        background-color: #F2F2F2;
    }
    h1{
        text-align: center;
        color: #333333;
    }
    .cardcontainer{
        
        background-color: white;
        margin-left: auto;
        margin-right: auto;
        transition: 0.3s;
    }
    .cardcontainer:hover{
        box-shadow: 0 0 45px gray;
    }
    .photo{
        height: 260px;
        width: 100%;
    }
    .photo img{
        height: 100%;
        width: 100%;
    }
    .txt1{
        margin: auto;
        text-align: center;
        font-size: 17px;
    }
    .content{
        width: 80%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        top: -33px;
    }
    .photos{
        width: 90px;
        height: 30px;
        background-color: #E74C3C;
        color: white;
        position: relative;
        top: -30px;
        padding-left: 10px;
        font-size: 20px;
    }
    .txt4{
        font-size:22px;
        position: relative;
        top: 33px;
    }
    .txt5{
        position: relative;
        top: 18px;
        color: #E74C3C;
        font-size: 23px;
    }
    .txt2{
        position: relative;
        top: 10px;
    }
    .cardcontainer:hover > .photo{
        height: 300px;
        animation: move1 0.5s ease both;
    }
    @keyframes move1{
        0%{height: 260px}
        100%{height: 200px}
    }
    .cardcontainer:hover > .content{
        height: 150px;
    }
    .footer{
        width: 80%;
        height: 100px;
        margin-left: auto;
        margin-right: auto;
        background-color: white;
        position: relative;
        top: -15px;
    }
    .btn{
        position: relative;
        top: 20px;
    }
    #heart{
        cursor: pointer;
    }
    .like{
        float: right;
        font-size: 22px;
        position: relative;
        top: 20px;
        color: #333333;
    }
    .fa-gratipay{
        margin-right: 10px;
        transition: 0.5s;
    }
    .fa-gratipay:hover{
        color: #E74C3C;
    }
    .txt3{
        color: gray;
        position: relative;
        top: 18px;
        font-size: 14px;
    }
    .comments{
        float: right;
        cursor: pointer;
    }
    .fa-clock, .fa-comments{
        margin-right: 7px;
    }
    .btn-success{
        background-color:#2c3e50;
        border:0px;
    }
</style>