import { createRouter, createWebHistory } from 'vue-router'

import Home from '../views/Home.vue'
import Profile from '../views/Profile.vue'
import Contact from '../views/Contact.vue'
import Project from '../views/Projects.vue'
import Category from '../views/Categories.vue'
import ProjectInfo from '../views/ProjectInfo.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/projects',
    name: 'project',
    component: Project
  }
  ,
  {
    path: '/categories',
    name: 'category',
    component: Category
  }
  ,
  {
    path: '/contacts',
    name: 'conatct',
    component: Contact
  }
  ,
  {
    path: '/profile',
    name: 'profile',
    component: Profile
  }
  ,
  {
    path: '/project-info',
    name: 'projectinfo',
    component: ProjectInfo
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
