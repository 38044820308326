<template>
<div class="container main-body">
    <div class="row">
       <div class="col-sm-6 col-md-4 col-12" v-for="project in projects" :key="project.id">
           <Project :project="project" />
       </div>
   </div>
</div>   
</template>

<script>
import Project from "../components/Project.vue";

export default{
    
    name:"Projects",
    data(){
        return{

        }
    },
    components:{
        Project
    },
    computed:{
        projects(){
            return this.$store.state.projects;
        }
    }
    
}
</script>

<style>

</style>