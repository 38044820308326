<template>
<div>
  <!--=============== HEADER ===============-->
        <header class="header" id="header">
            <nav class="nav container">
                <router-link to="/" class="nav__logo">Amani Services</router-link>

                <div class="nav__menu" id="nav-menu">
                    <ul class="nav__list">
                        <li class="nav__item">
                            <router-link to="/" class="nav__link">
                                <i class='fa fa-home nav__icon'></i>
                                <span class="nav__name">Home</span>
                            </router-link>
                        </li>

                        <li class="nav__item">
                            <router-link to="/projects" class="nav__link">
                                <i class='fa fa-archive nav__icon'></i>
                                <span class="nav__name">Projects</span>
                            </router-link>
                        </li>

                        <li class="nav__item">
                            <router-link to="/categories" class="nav__link">
                                <i class='fa fa-th-large nav__icon'></i>
                                <span class="nav__name">Categories</span>
                            </router-link>
                        </li>

                        <li class="nav__item">
                            <router-link to="/contacts" class="nav__link">
                                <i class='fa fa-phone nav__icon'></i>
                                <span class="nav__name">Contacts</span>
                            </router-link>
                        </li>

                        <li class="nav__item">
                            <router-link to="/profile" class="nav__link">
                                <i class='fa fa-user nav__icon'></i>
                                <span class="nav__name">Pofiles</span>
                            </router-link>
                        </li>

                       
                    </ul>
                </div>

                <img src="/assets/img/perfil.png" alt="" class="nav__img">
            </nav>
        </header>
        
        <main class="">
            <!--=============== HOME ===============-->
                <router-view></router-view>
        </main>

  
</div>
  
</template>

<script>

   export default{
       components:{

       },
        mounted(){
          this.$store.commit('updateWishlistFromLocalStorage')
        }
   }

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 0px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
