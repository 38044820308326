<template>
     <!-- Masthead-->
        <header class="masthead" v-bind:style="{ 'background-image': 'url(' + this.image + ')' }">
            <div class=" px-4 px-lg-5 h-100">
                <div class="row gx-4 gx-lg-5 h-100 align-items-center justify-content-center text-center">
                    <div class="col-lg-8 align-self-end">
                        <h1 class="text-white font-weight-bold">More Than {{projects}} Top Eletronics projects</h1>
                        <hr class="divider" />
                    </div>
                    <div class="col-lg-8 align-self-baseline">
                        <p class="text-white-75 mb-5">This is your favorite Electronics projects,
                             Now you can enjoy to views and buy Best project for Academic, Social, 
                             Industrial and Entertainment Purpose</p>
                        <router-link class="btn btn-primary btn-xl" to="/projects">View Projects</router-link>
                    </div>
                </div>
            </div>
        </header>
</template>

<script>
//import Project from "../components/Project.vue";
import image from '../../public/assets/img/tim.jpg'

export default{
    
    name:"Home",
    data(){
        return{
            image:image
        }
    },
    components:{
        //Project
    },
    computed:{
        projects(){
            return this.$store.state.projects.length;
        }
    }
    
}

</script>

<style scoped>
header.masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  /*background: linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url("/assets/img/bg-masthead.jpg");*/
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
header.masthead h1, header.masthead .h1 {
  font-size: 2.25rem;
}
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1, header.masthead .h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  header.masthead h1, header.masthead .h1 {
    font-size: 3.5rem;
  }
}

.btn-xl {
  padding: 1.25rem 2.25rem;
  font-size: 0.85rem;
  font-weight: 700;
  text-transform: uppercase;
  border: none;
  border-radius: 10rem;
}

.text-white-75 {
  color: rgba(255, 255, 255, 0.75) !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-end {
  align-self: flex-end !important;
}
</style>