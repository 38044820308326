<template>
    <div class="container main-body">
       <div class="row">
           <div class="col-12">
            <img src="/assets/img/portfolio/thumbnails/1.jpg" alt=""/>
           </div>
           
           <div class="col-sm-8 col-md-3 text-center"></div>

           <div class=" col-sm-8 col-md-6 text-center">
            <h5 class="mt-2">Driving the Green Revolution in Transportation</h5>
            <p>To view this Project Pay Tsh3,000 Through you mobile Pesa services</p>
           <input type="text" width="80%" class="form-control" placeholder="Enter number eg 0768448525"/>
           <button class="btn btn-success m-2">Allow Payment</button>
           </div>
           <div class="col-sm-8 col-md-3 text-center"></div>
       </div>
    </div>    
</template>

<script>


export default {
    data() {
        
    },
}
</script>

<style>
</style>
